//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
// import StyledLink from "../common/StyledLink.vue";
import YtModal from "../common/YtModal.vue";
export default {
  components: { YtModal, ButtonsTray },
  name: "VideoText",

  props: {
    // textSize: {
    // 	type: String,
    // 	default: "medium",
    // },
  },
  data() {
    return {
      modalShown: false,
      videoId: "Uf89FRDECFc",
    };
  },
  computed: {
    textSize() {
      return this.propsData.font_size;
    },
  },
};
