//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
// import StyledLink from "../common/StyledLink.vue";
export default {
  components: { ButtonsTray },
  name: "FirstScreenLanding",
  props: {
    main_screen: {
        type: Object,
        default: () => {}
    },
  },
  data() {
    return {};
  },
  computed: {
    hasImg() {
      return Boolean(this.main_screen.image);
    },
    type() {
      switch (this.main_screen.widget_type) {
        case "type_1":
          return "1";
        case "type_2":
          return "2";
        case "type_3":
          return "3";
        default:
          return "1";
      }
    },
    classList() {
      let classlist = "";
      switch (this.type) {
        case "1":
          classlist += "type1";
          break;
        case "2":
          classlist += "type2";
          break;
        case "3":
          classlist += "type3";
          break;
        default:
          classlist += "";
          break;
      }
      if (this.main_screen.image) classlist += " hasImg";
      return classlist;
    },
    img() {
        // && +this.main_screen.with_image_fon
      if (this.main_screen.image && window.innerWidth > "768") {
        return {
          backgroundImage: `url('${this.path(this.main_screen.image)}')`,
        };
      }
      else if (this.main_screen.image_mob && window.innerWidth < "768") {
        return {
          backgroundImage: `url('${this.path(this.main_screen.image_mob)}')`,
        };
      } else {
        return null;
      }
    },
  },
};
