//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import { mapGetters } from "vuex";
import DropDownLink from "../common/DropDownLink.vue";
import MenuAccordion from "../common/MenuAccordion.vue";
import LangSwitch2 from "../common/LangSwitch2.vue";
export default {
  components: { DropDownLink, MenuAccordion, LangSwitch2 },
  name: "BurgerMenu",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getSocialLinks", "getLogo", "getHeaderMenu"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    socName(s) {
      switch (s) {
        case "fb":
          return "Facebook";

        case "yt":
          return "Youtube";

        case "tw":
          return "Twitter";
      }
    },
  },
  created() {
    if (!this.getBurgerMenu) {
      adapter.post(
        "/api/menu/get-by-ids",
        {
          ids: [49],
          lang: this.locale,
        },
        this
      );
      // .then((response) => {
      //   this.$store.commit("SETBURGERMENU", response.items);
      // });
    }
  },
};
