//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import SectionComponent from "../components/common/SectionComponent.vue";
export default {
  name: "Landing",
  components: {
    SectionComponent,
  },
  data() {
    return {
      loaded: false,

      constructorComponents: [],
      model: null,
      translate: null,
    };
  },
  computed: {},
  created() {
    let url;
    let props;
    if (this.$route.query.prevw) {
        props.prevw = this.$route.query.prevw;
    }
    switch (this.$route.name) {
      case "home":
        url = "/api/get-main-page";
        props = {
          lang: this.$i18n.locale,
        };
        break;
      case "landing":
        url = "/api/landing/get-by-slug";
        props = {
          lang: this.$i18n.locale,
          slug: this.$route.params.slug,
        };
        break;
      default:
        url = "/api/page/get-by-slug";
        props = {
          lang: this.$i18n.locale,
          slug: this.$route.params.slug,
        };
        break;
    }
    adapter
      .post(url, props, this)
      .then((response) => {
		console.log(response);
        if (response.contacts) {
          this.constructorComponents.push({
            component: "contactsComponent",
            content: {
              contacts: response.contacts,
              api_key: response.maps_api_key,
            },
            visibility: "1",
          });
          this.constructorComponents = [
            ...this.constructorComponents,
            ...response.constructor,
          ];
        } else {
          this.constructorComponents = response.constructor;
        }
        this.model = response.model;
        this.translate = response.translate;
        this.main_screen = {
            title: response.translate.title,
            description: response.translate.description,
            buttons: response.translate.main_screen,
            image: response.translate.image,
            image_mob: response.translate.image_mob,
        }
        this.setMeta();
        this.loaded = true;
      })
      .catch((e) => {
        this.handleError(e);
        this.$router.push({name: 'error'})
      });
  },
};
