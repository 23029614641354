//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TableComponent",
  data() {
    return {
      mob: false,
    };
  },
  mounted() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize);
  },
  methods: {
    updateSize() {
      if (innerWidth > 568) this.mob = false;
      else this.mob = true;
    },
    getIconsRow(row) {
      const numberKeys = [];
      for (const key in row) {
        if (Object.hasOwnProperty.call(row, key)) {
          if (!isNaN(key)) numberKeys.push(key);
        }
      }
      const returnObj = { icon: row.icon, row: [] };
      numberKeys.forEach((key) => returnObj.row.push(row[key]));
      return returnObj;
    },
  },
  computed: {
    rows() {
      if (!this.mob) {
        return this.propsData.rows;
      } else {
        return this.propsData.mob_rows || this.propsData.rows;
      }
    },
    tableType() {
      if (!this.mob) {
        return this.propsData.style_type;
      } else {
        return this.propsData.mob_style_type || this.propsData.style_type;
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.updateSize);
  },
};
