//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: ["videoId"],
	components: {},
	data() {
		return {
			side: "w",
			calculate: false,
			parsedVideoId: null,
		};
	},
	methods: {
		youtubeParser(url) {
			var regExp =
				/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
			var match = url.match(regExp);
			return match && match[7].length == 11 ? match[7] : false;
		},
	},
	computed: {
		playerWidth() {
			if (this.calculate) return this.$refs.wrapper1.offsetWidth;
			else return null;
		},
		playerHeight() {
			if (this.calculate) return Math.floor((this.playerWidth * 9) / 16);
			else return null;
		},
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.calculate = false;
			this.calculate = true;
		});
		setTimeout(() => {
			this.calculate = true;
		}, 50);

		this.parsedVideoId = this.youtubeParser(this.videoId);
	},
};
