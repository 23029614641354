//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "ArticleBtnsTray",
  computed: {
    btns() {
      return [
        {
          link: this.propsData.link,
          icon: null,
          type: "fill",
          text: this.propsData.title,
          form_data: this.propsData.form_data
        },
      ];
    },
  },
};
