//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BlogArticleLink",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    translate() {
      return this.item?.translate || {};
    },
    model() {
      return this.item?.model || {};
    },
  },
};
