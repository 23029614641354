//
//
//
//
//
//

import Flickity from "vue-flickity";
export default {
  name: "SliderComponent",
  components: { Flickity },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
};
