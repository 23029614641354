//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import { mapGetters } from "vuex";
import DefaultInput from "./DefaultInputFooter.vue";
import { required, email } from "vuelidate/lib/validators";
export default {
  components: { DefaultInput },
  name: "FooterSubscribeForm",
  data() {
    return {
      showError: false,
      inp: true,

      email: "",

      sending: false,
      modalShown: false,
    };
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  computed: {
    ...mapGetters(["options"]),
  },
  methods: {
    formSend() {
      this.showError = true;
      if (!this.$v.$invalid) {
        adapter
          .post(
            "/api/news/subscribe",
            {
              email: this.email,
            },
            this
          )
          .then((response) => {
            console.log(response);
            this.modalShown = true;
            setTimeout(() => {
              this.modalShown = false;
              this.showError = false;
              this.email = "";
              this.inp = false;
              setTimeout(() => {
                this.inp = true;
              }, 500);
            }, 3000);
          });
      }
    },
  },
};
