//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { adapter } from "@/utils.js";
import SiteHeader from "../components/parts/SiteHeader.vue";
import SiteFooter from "../components/parts/SiteFooter.vue";
import BurgerMenu from "../components/parts/BurgerMenu.vue";
// import CookieMessage from "../components/parts/CookieMessage.vue";
import Cookie from "js-cookie";
import CanvasBlur from '../components/CanvasBlur.vue';
export default {
  components: {
    SiteHeader,
    SiteFooter,
    BurgerMenu,
    CanvasBlur,
    // CookieMessage,
  },
  data() {
    return {
      burgerShown: false,
      cookieShown: false,
    };
  },
  computed: {
    routeKey() {
      switch (this.$route.name) {
        case "events":
          return "events";
        default:
          return this.$route.fullPath;
      }
    },
  },
  watch: {
    $route() {
        this.burgerShown = false;
    }
  },
  methods: {
    onCookieAccept() {
      Cookie.set("cookieAccept", true);
      this.cookieShown = false;
    },
  },
  mounted() {
    this.axios
      .post("/api/settings/all", {
        lang: this.locale,
      })
      .then((result) => {
        const data = result.data.data;
        this.$store.commit("SETOPTIONS", data);
      });
    this.axios
      .post("/api/menu/get-by-ids", {
        lang: this.locale,
        ids: [1, 21],
      })
      .then((result) => {
        const data = result.data.data;
        this.$store.commit("SETHEADERMENU", data.items[1]);
        this.$store.commit("SETFOOTERMENU", data.items[21]);
      });
    // setTimeout(() => {
    // 	if (!Cookie.get("cookieAccept")) {
    // 		this.cookieShown = true;
    // 	}
    // }, 1000);
    // adapter
    // 	.post("/api/settings/all", { lang: this.locale }, this)
    // 	.then((response) => {
    // 		this.$store.commit("SETOPTIONS", response);
    // 	});
  },
};
