//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArticleBtnsTray from "../ArticleParts/ArticleBtnsTray.vue";
import ArticleImage from "../ArticleParts/ArticleImage.vue";
import ArticleImageAndText from "../ArticleParts/ArticleImageAndText.vue";
import ArticleList from "../ArticleParts/ArticleList.vue";
import ArticleSimpleText from "../ArticleParts/ArticleSimpleText.vue";
import ArticleTop from "../ArticleParts/ArticleTop.vue";
import TextButton from "../ArticleParts/TextButton.vue";
import TextQuote from "../ArticleParts/TextQuote.vue";
import VideoFrame from "../ArticleParts/VideoFrame.vue";
import ImageSlider from "./ImageSlider.vue";
export default {
  name: "ArticleBody",
  components: {
    ArticleTop,
    VideoFrame,
    TextButton,
    quotes: TextQuote,
    "simple-text": ArticleSimpleText,
    "image-and-text": ArticleImageAndText,
    gallery: ImageSlider,
    button_component: ArticleBtnsTray,
    list: ArticleList,
    "image-blog": ArticleImage,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    translate: {
      type: Object,
      default: () => {},
    },
    constructorList: {
      type: Array,
      default: () => [],
    },
  },
};
