//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    inputData: {
      type: Object,
      default: () => {},
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    change() {
      //   this.value[i] = !this.value[i];
      let a = JSON.stringify(this.value);
      this.$set(this, "value", JSON.parse(a));
      this.$emit("input", Boolean(a));
    },
  },
};
