//
//
//
//
//
//
//
//

export default {
	name: "appLink",
	props: {
		to: {
			type: [Object, String],
			default: null,
		},
		target: {
			type: String,
			default: "",
		},
	},
	computed: {
		isExternal() {
			if (typeof this.to == "string") {
				if (this.isExtUrl(this.to)) return true;
				else return false;
			} else if (typeof this.to == "object") {
				return false;
			} else {
				return null;
			}
		},
		toUrl() {
			return this.to == null ? "" : this.to;
		},
	},
	methods: {},
};
