//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import formText from "../formWidget/formText.vue";
import formInput from "../formWidget/formInput.vue";
import formTitle from "../formWidget/formTitle.vue";
import FormEditor from "../formWidget/formEditor.vue";
import FormCheckbox from "../formWidget/formCheckbox.vue";
import FormSubmit from '../formWidget/formSubmit.vue';
import FormNumber from '../formWidget/formNumber.vue';
export default {
  components: { formInput, formText, formTitle, FormEditor, FormCheckbox, FormSubmit, FormNumber },
  name: "FormWidget",
  data() {
    return {
      InputValues: {},
      showError: false,
      success: false,
    //   count: null,
    };
  },
  computed: {
    ...mapGetters(["getLogo", "getMenuData", "getFormId"]),
  },
  validations() {
    const temp = {};
    temp.InputValues = {};
    this.getMenuData.forEach((el) => {
      if (el.show_in_message === "1" && el.visibility == "1") {
        temp.InputValues[el.name] = {};
      }
      this.getMenuData.forEach((el) => {
        if (el.rules && el.visibility == "1") {
          let rules = {};
          if ( this.count ) {
            rules.count = this.count
          }
          if (el.rules.required && el.rules.required !== "0" ) {
            rules = { ...rules, ...{ required } };
          }
          if (el.rules.email && el.rules.email !== "0" ) {
            rules = { ...rules, ...{ email } };
          }
          if (el.rules.min) {
            rules = { ...rules, ...{ minLength: minLength(el.rules.min) } };
          }
          if (el.rules.max) {
            rules = { ...rules, ...{ maxLength: maxLength(el.rules.max) } };
          }
          temp.InputValues[el.name] = {
            ...rules,
          };
        }
      });
    });

    return temp;
  },
  methods: {
    ...mapActions(["setMenuState"]),
    closeOutside(event) {
        if ( event.target.classList.contains('modal')) {
            this.close()
        }
    },
    changeChekbox(name, data) {
      this[name] = data;
    },
    close() {
      this.setMenuState(false);
    },
    changeCount(val) {
        this.count = val
    },
    sendForm() {
      const option = Object.assign(this.InputValues, {
        form_id: this.getFormId,
      });
      console.log(option);
      this.showError = true;
      if (!this.$v.$invalid) {
        this.axios.post("/api/request/send", option).then(() => {
          this.success = true;
        });
      }
    },
  },
  mounted() {
    this.getMenuData.forEach((el) => {
      if (el.show_in_message === "1" && el.visibility == "1") {
        this.$set(this.InputValues, el.name, "");
      }
    });
  },
};
