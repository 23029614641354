//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rtParams } from "@/utils.js";
export default {
	name: "LangSwitch",
	data() {
		return {
			active: false,
		};
	},
	computed: {
		...rtParams,
	},
	methods: {},
};
