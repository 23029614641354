//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "articleTop",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      copiedTimer: null,
      isCopiedLabel: false,
    };
  },
  methods: {
    copyToClipboard() {
      this.$refs.copyInpt.value = window.location.href;
      this.$refs.copyInpt.select();
      document.execCommand("copy");
      this.isCopiedLabel = true;
      this.copiedTimer = setTimeout(() => {
        this.isCopiedLabel = false;
      }, 2000);
    },
  },
  computed: {
    currentLocation() {
      return window.location.href;
    },
  },
};
