//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
import AccordionItem from "../parts/AccordionItem.vue";
export default {
  components: { AccordionItem, ButtonsTray },
  name: "accordionSection",
  computed: {
    num() {
      return this.propsData.type == "numerical" ? true : false;
    },
  },
};
