//
//
//
//
//
//
//
//
//

export default {
  name: "TextQuote",
};
