//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import defaultinput from "./DefaultInputMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "DefaultInput",
  mixins: [defaultinput],
  props: {
      success: {
          type: Boolean,
      },
  },
  computed: {
    ...mapGetters(["options"]),
  },
};
