//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuAccordion from "./MenuAccordion.vue";
export default {
  components: { MenuAccordion },
  name: "DropDownLink",
  data() {
    return {
      hovered: false,
      left: "0",
    };
  },
  computed: {
    group1() {
      if (this.propsData.children.length > 1) {
        const t = [];
        const l = Math.ceil(this.propsData.children.length / 2);
        for (let i = 0; i < l; i++) {
          t.push(this.propsData.children[i]);
        }
        return t;
      } else {
        return this.propsData.children;
      }
    },
    group2() {
      if (this.propsData.children.length > 1) {
        const t = [];
        const start = Math.ceil(this.propsData.children.length / 2);
        const l = this.propsData.children.length;
        for (let i = start; i < l; i++) {
          t.push(this.propsData.children[i]);
        }
        return t;
      } else return null;
    },
  },
  mounted() {
    this.calculate();
    window.addEventListener("resize", this.calculate);
  },
  methods: {
    onLeave() {
      // setTimeout(() => {
      this.hovered = false;
      // }, 300);
    },
    onEnter() {
      this.hovered = true;
    },
    calculate() {
      const el = this.$el.closest(".rightSide");
      const right = el.getBoundingClientRect().right;
      const dif =
        right -
        (this.$refs.slide.getBoundingClientRect().width +
          this.$el.getBoundingClientRect().x);
      if (dif < 0) {
        this.left = `${dif}px`;
      } else {
        this.left = `0`;
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.calculate);
  },
};
