//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "../common/ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "ImageSection",
  props: {},
  computed: {
    full() {
      return this.propsData.width_type == 100 ? true : false;
    },
  },
};
