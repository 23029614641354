//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: "pagination",
	props: ["compdata"],
	methods: {
		onPrew(s) {
			if (s) this.$emit("click", "prev");
		},
		onNext(s) {
			if (s) this.$emit("click", "next");
		},
	},
};
