//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adapter } from "@/utils.js";
import BlogList from "../components/sections/BlogList.vue";
export default {
  components: { BlogList },
  name: "BlogPage",
  data() {
    return {
      currentPage: 1,
      totalPage: 1,
      blogItems: [],
      page: null,
    };
  },
  created() {
    let url;
    let props;
    if (this.$route.name === "news") {
      url = "/api/blog/articles/all";
      props = {
        lang: this.$i18n.locale,
      };
    } else if (this.$route.name === "rubric") {
      url = "/api/blog/articles/get-by-category-slug";
      props = {
        lang: this.$i18n.locale,
        slug: this.$route.params.slug,
      };
    }
    adapter
      .post(url, props, this)
      .then((response) => {
        this.page = response.page;
        this.blogItems = response.items.models;
        this.currentPage = response.items.paginate.current_page;
        this.totalPage = Math.ceil(
          response.items.paginate.total / response.items.paginate.per_page
        );
      })
      .catch((e) => {
        this.handleError(e);
      });
  },
};
