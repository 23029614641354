//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonsTray from "./ButtonsTray.vue";
export default {
  components: { ButtonsTray },
  name: "Cta",
};
